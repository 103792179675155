import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy around the use of anti-virus systems in its lab environments.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`To establish requirements which must be met by all computers connected
to Data Migrators lab networks to ensure effective virus detection and
prevention.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all Data Migrators lab computers that are
PC-based or utilize PC-file directory sharing. This includes, but is not
limited to, desktop computers, laptop computers, file/ftp/tftp/proxy
servers, and any PC based lab equipment such as traffic generators.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`All Data Migrators PC-based lab computers must have DSata Migrators’
standard, supported anti-virus software installed and
scheduled to run at regular intervals. In addition, the anti-virus
software and the virus pattern files must be kept up-to-date.
Virus-infected computers must be removed from the network until they are
verified as virus-free. Lab Admins/Lab Managers are responsible for
creating procedures that ensure anti-virus software is run at regular
intervals, and computers are verified as virus-free. Any activities with
the intention to create and/or distribute malicious programs into
Data Migrators’ networks (e.g., viruses, worms, Trojan horses,
e-mail bombs, etc.) are prohibited, in accordance with the `}<em parentName="p">{`Acceptable
Use Policy`}</em>{`.`}</p>
    <p>{`Refer to Data Migrators\\‘s `}<em parentName="p">{`Anti-Virus Recommended Processes`}</em>{` to help
prevent virus problems.`}</p>
    <p>{`Noted exceptions: Machines with operating systems other than those based
on Microsoft products are excepted at the current time.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Standards, Policies and Processes`}</h2>
    <ul>
      <li parentName="ul">{`Acceptable Use Policy`}</li>
      <li parentName="ul">{`Anti-Virus Recommended Processes`}</li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      